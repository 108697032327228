import axios from "axios";
import Vue from "vue";

var api = Vue.prototype.$base_url;

/**
 * 
 * @param {*} method 请求方式 : post || get
 * @param {*} param 请求参数：{}
 * @returns 
 */
// 轮播图
export function banner (method, param) {
    return axios.request({
        url: Vue.prototype.$base_url + '/content.banner/list',
        method,
        data: param,
        
    })
}

// 导航栏
export function navList (method, param) {
    return axios.request({
        url: Vue.prototype.$base_url + '/content.navigation/list',
        method,
        data: param,
        
    })
}

// 留言
export function leaveComment (method, param) {
    return axios.request({
        url: Vue.prototype.$base_url + '/content.comment/leaveComment',
        method,
        data: param,
        
    })
}

// 关于我们 的 团队风采
export function teamStyle(method,param) {
  return axios.request({
    url:Vue.prototype.$base_url+'/content.images/list',
    method,
    params:param
  })
}

// 底部信息
export function bottomInfo(method,param){
  return axios.request({
    url:Vue.prototype.$base_url+'/content.bottom/list',
    method,
    data:param
  })
}

