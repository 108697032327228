import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  // 首页
  {
    path: '/index',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  // 产品中心
  {
    path: '/procduct',
    name: 'procduct',
    component: () => import(/* webpackChunkName: "about" */ '../views/procduct.vue')
  },
  // 运营服务
  {
    path: '/service',
    name: 'service',
    component: () => import(/* webpackChunkName: "about" */ '../views/service.vue')
  },
  // 渠道合作
  {
    path: '/cooperation',
    name: 'cooperation',
    component: () => import(/* webpackChunkName: "about" */ '../views/cooperation.vue')
  },
  // 关于我们
  {
    path: '/aboutus',
    name: 'aboutus',
    // component: aboutus
    component: () => import(/* webpackChunkName: "about" */ '../views/aboutus.vue')
  },
]

const router = new VueRouter({
  routes
})
// 跳转页面回到顶部
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  next()
})
export default router
