import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
Vue.use(preview)
Vue.use(ElementUI);

Vue.config.productionTip = false

// vue原型定义请求接口域名
// Vue.prototype.$base_url = 'http://shop.com/index.php?s=/offcial';
Vue.prototype.$base_url = '../index.php?s=/offcial'; // 正式环境


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')