<template>
  <div id="app">
    <div class="top">
      <el-carousel height="590px" :interval="2000">
        <el-carousel-item v-for="item in bannerList" :key="item.banner_id">
          <img :src="item.image_url" alt="" width="100%" />
        </el-carousel-item>
      </el-carousel>
      <div :class="pageScorll ? 'topNav2' : 'topNav1'">
        <nav>
          <img src="./assets/logo1.png" alt="" />
          <img src="./assets/logo2.png" alt="" />
          <router-link
            :to="item.nav_link"
            v-for="item in navList"
            :key="item.navigation_id"
          >
            {{ item.nav_name }}
          </router-link>
        </nav>
        <div>
          <a
            href="/store/#/passport/login"
            class="boxStyle"
            >登录</a
          >
          <span href="" @click="goLeaveMessage" class="boxStyle">免费试用</span>
          <!-- <router-link
            :to="{ name: 'aboutus', query: { bottom: 1 } }"
            class="boxStyle"
            >免费试用</router-link> -->
        </div>
      </div>
    </div>
    <!-- 悬浮按钮 -->
    <div class="rightBtn">
      <el-popover placement="left" trigger="hover" slot="customer" content="">
        <div class="customer">
          <img src="./assets/customer.png" alt="" srcset="" />
        </div>
        <img slot="reference" src="./assets/btn1.png" alt="" />
      </el-popover>

      <!-- <img src="./assets/btn2.png" alt="" /> -->
      <el-popover
        placement="left"
        width="180"
        trigger="hover"
        slot="phone"
        content=""
      >
        <div class="phone">
          <p>
            客服电话：
            <span style="color: #f57419">17688755816</span>
          </p>
          <p>商业合作：17788784394</p>
        </div>
        <img slot="reference" src="./assets/btn3.png" alt="" />
      </el-popover>
      <img src="./assets/btn4.png" alt="" @click="goTop" />
    </div>
    <router-view />
    <div class="bottom">
      <div class="bottomTop">
        <div class="topLeft">
          <!-- <p>联系我们</p> -->
          <div class="leftData" v-for="item in phone" :key="item.bottom_id">
            <span>{{ item.key }}：{{ item.value }}</span>
          </div>
        </div>
        <div class="topRight">
          <img src="./assets/code.png" alt="" preview />
        </div>
      </div>
      <div class="bottomFoot">
        <span>Copyright 2017-2022棋煜科技(shop.lqsaas.com)   粤ICP备2020105493号-3</span>
      </div>
    </div>
  </div>
</template>

<script>
import { banner, navList, bottomInfo } from "./api/index.js";
import storage from "good-storage";
export default {
  data() {
    return {
      bannerList: [], // 轮播图列表
      navList: [], // 导航栏列表
      pageScorll: false, //导航栏滚动
      bottomList: [], //底部信息
      phone: [], //客服电话
      link: [], //友情链接
    };
  },
  created() {
    this.getNav();
    this.getBanner();
    this.getBottomInfo();
  },
  mounted() {
    // 页面滚动
    window.addEventListener("scroll", this.windowScroll); //监听页面滚动
  },
  methods: {
    // 点击试用跳转留言位置
    goLeaveMessage() {
      if (this.$route.path === "/aboutus") {
        window.scrollTo(0, 50000);
      } else {
        this.$router.push({
          name: `aboutus`, // 这里只能是name，对应路由
          query: { bottom: 1 },
        });
      }
    },
    // 获取导航栏列表
    getNav() {
      navList("get", {})
        .then((res) => {
          this.navList = res.data.data.data;
        })
        .catch((err) => {});
    },
    // 获取轮播图列表
    getBanner() {
      banner("get", {})
        .then((res) => {
          this.bannerList = res.data.data.data;
        })
        .catch((err) => {});
    },
    // 一键置顶
    goTop() {
      document.documentElement.scrollTop = 0;
    },
    // 页面滚动
    windowScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop) {
        this.pageScorll = true;
      } else {
        this.pageScorll = false;
      }
    },
    // 获取底部信息
    getBottomInfo() {
      bottomInfo("get", {})
        .then((res) => {
          this.bottomList = res.data.data;
          for (let item = 0; item < this.bottomList.length; item++) {
            if (this.bottomList[item].type === 1) {
              this.phone[item] = this.bottomList[item];
            } else {
              this.link[item] = this.bottomList[item];
            }
          }
        })
        .catch((err) => {});
    },
  },
  destroyed() {
    //销毁滚动事件
    window.removeEventListener("scroll", this.windowScroll);
  },
};
</script>

<style lang="less">
// 微信客服
.customer {
  img {
    margin-left: 10px;
  }
}
// 咨询热线
.phone {
  text-align: center;
}
#app {
  text-align: center;
  width: 100%;
  // width: 1900px;
}
* {
  padding: 0px;
  margin: 0px;
}
.top {
  position: relative;
}
.boxStyle {
  border: 1px solid #fff;
  padding: 2px 10px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}
.rightBtn {
  z-index: 99;
  position: fixed;
  top: 330px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin-bottom: 10px;
    cursor: pointer;
  }
}
.topNav1 {
  padding: 19px 300px;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  min-width: 900px;
}
.topNav2 {
  padding: 19px 300px;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  background-color: #f57419;
  min-width: 900px;
}
nav {
  display: flex;
  align-items: center;
  img:nth-child(2) {
    margin: 0 32px 0 16px;
  }
}
a {
  font-weight: bold;
  color: #fff;
  margin-right: 30px;
  text-decoration: none;
  font-size: 16px;
  font-size: 16px;
  font-family: Source Han Sans SC, Source Han Sans SC-Regular;
  font-weight: 400;
  cursor: pointer;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  padding-bottom: 5px;
}
nav a.router-link-exact-active {
  border-bottom: 2px solid #fff;
  padding-bottom: 5px;
}
.bottom {
  background-color: #f57419;
  color: #fff;
  padding: 32px 200px;
  .bottomTop {
    display: flex;
    margin-bottom: 59px;
    font-size: 16px;
    .topLeft {
      flex: 1;
      text-align: left;
      .leftData {
        display: flex;
        justify-content: space-between;
        width: calc(100% / 2);
        display: inline-block;
        padding: 16px 0;
        text-align: left;
      }
      p {
        text-align: center;
      }
    }
    .topRight {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: end;
    }
  }
  .bottomFoot {
    span {
      font-size: 14px;
    }
  }
}
</style>
